import React, { useState } from "react";
import { poolList } from "../../../Lists/products/poolList";
import Category from "../../../components/UI/Category";
import classes from "./poolCleaning.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import poolcleaningProductsList from "../../../Lists/products/poolProducts/poolCleaningList";
import poolEmbedProductList from "../../../Lists/products/poolProducts/poolEmbedList";
import poolBombProductList from "../../../Lists/products/poolProducts/poolBombList";
import poolChemicalProductList from "../../../Lists/products/poolProducts/poolChemicalList";
import poolDecorationProductList from "./../../../Lists/products/poolProducts/poolDecorationList";
import poolDisinfectionProductList from "./../../../Lists/products/poolProducts/poolDisinfectionList";
import poolFilterProductList from "./../../../Lists/products/poolProducts/poolFilterList";
import poolHeatingProductList from "./../../../Lists/products/poolProducts/poolHeatingList";
import poolLightProductList from "./../../../Lists/products/poolProducts/poolLightList";

const PoolProducts = () => {
  const newArray = [];

  const params = useParams();

  const listCategory = poolList.find(
    (category) => category.path === params.poolId
  );
  const chamicals = "chemicals" === params.poolId;

  const showSubcategory = () => {
    if ("chemicals" === params.poolId) {
      return;
    } else
      return (
        <section>
          <Subcategory
            onListChange={onListChange}
            onsubListChange={onsubListChange}
            categoryList={listCategory.list}
          ></Subcategory>
        </section>
      );
  };

  const findListHandle = (path) => {
    if (path === "cleaning") {
      return poolcleaningProductsList;
    }
    if (path === "embed") {
      return poolEmbedProductList;
    }
    if (path === "decoration") {
      return poolDecorationProductList;
    }
    if (path === "bombs") {
      return poolBombProductList;
    }
    if (path === "filter") {
      return poolFilterProductList;
    }
    if (path === "lighting") {
      return poolLightProductList;
    }
    if (path === "chemicals") {
      return poolChemicalProductList;
    }
    if (path === "heating") {
      return poolHeatingProductList;
    }
    if (path === "disinfection") {
      return poolDisinfectionProductList;
    }
  };
  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };

  return (
    <div className={classes.showProducts}>
      {showSubcategory()}
      <section
        className={` ${chamicals ? classes.onlyProducts : classes.products}`}
      >
        <Category title={listCategory.title} list={displayedList}></Category>
      </section>
    </div>
  );
};

export default PoolProducts;
