import React from "react";
import control from "../../images/productsImages/controlBoxes.jpg";
import classes from "./Products.module.css";
import Template from "../../components/UI/Template";
import EmployeeCard from "../../components/UI/EmployeeCard";
import { rene } from "../../Lists/employeeList";

const Panel = () => {
  const technical = rene;
  const text1 =
    "Somos también importadores y ensambladores de tableros de control y protección para equipos de bombeo.";
  const text2 =
    "Traemos equipos especializados para uso en equipos de bombeo pero también podemos ensamblar en nuestros talleres tableros a la medida.";
  return (
    <>
      <Template
        title="TABLEROS DE CONTROL Y PROTECCIÓN"
        image={control}
        text2={text1}
        text={text2}
        buttonHandler={""}
        buttonName="Contáctenos"
      ></Template>
      <section className={classes.employeeSection}>
        <h1>Técnico</h1>
        <h4>Conozca a nuestro funcionario en tableros personalizados.</h4>
        <EmployeeCard
          key={technical._id}
          name={technical.name}
          number={technical.phoneNumber}
          email={technical.email}
          photo={technical.image}
        ></EmployeeCard>
      </section>
    </>
  );
};

export default Panel;
