import surfaceproduct1 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/AUTOCEBANTES/pioneerGS.jpg";
import surfaceproduct2 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Caracol/SerieAG.jpg";
import surfaceproduct3 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Caracol/SERIED-01.png";
import surfaceproduct4 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Caracol/CEPBIGELECTROBOMBAS.png";
import surfaceproduct5 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Cascasapartida/HDaserie340wilo.jpg";
import surfaceproduct6 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Multietapa/SerieCVI.jpg";
import surfaceproduct7 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Multietapa/SerieVR.jpg";
import surfaceproduct8 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Multietapa/GrundfosSerieCR.jpg";
import surfaceproduct9 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/Multietapa/VPS.png";
import surfaceproduct10 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/TipoANSI/FIT.jpg";
import surfaceproduct11 from "../../../images/productsImages/surfaceImages/Industrial/Bombassuperficie/TipoANSI/OSDserie460.jpg";
import surfaceproduct12 from "../../../images/productsImages/surfaceImages/Industrial/Motoressuperficie/BALDOR/AcomplamientoJM2Baldor.png";
import surfaceproduct13 from "../../../images/productsImages/surfaceImages/Industrial/Motoressuperficie/BALDOR/AcomplamientoJM-baldor.png";
import surfaceproduct14 from "../../../images/productsImages/surfaceImages/Industrial/Motoressuperficie/GENERALELECTRIC/NEMAPremium.png";
import surfaceproduct15 from "../../../images/productsImages/surfaceImages/Industrial/Motoressuperficie/GENERALELECTRIC/Motores.png";
import pdf1 from "../../../pdf/surface/industrial/PioneerGS.pdf";
import pdf2 from "../../../pdf/surface/industrial/AG.pdf";
import pdf3 from "../../../pdf/surface/industrial/SERIED.pdf";
import pdf4 from "../../../pdf/surface/industrial/CEP.pdf";
import pdf5 from "../../../pdf/surface/industrial/serieHD.pdf";
import pdf6 from "../../../pdf/surface/industrial/CVI.pdf";
import pdf7 from "../../../pdf/surface/industrial/VR.pdf";
import pdf8 from "../../../pdf/surface/industrial/GRUNDFOS.pdf";
import pdf9 from "../../../pdf/surface/industrial/VPS.pdf";
import pdf10 from "../../../pdf/surface/industrial/fit.pdf";
import pdf11 from "../../../pdf/surface/industrial/OSD.pdf";
import pdf14 from "../../../pdf/surface/industrial/UltraVerticalSeries_GEA.pdf";
import pdf15 from "../../../pdf/surface/industrial/TEFCGeneralPurpose.pdf";

const surfaceIndustrialProductList = [
  {
    _id: "surcatindbom001a",
    image: surfaceproduct1,
    title: "Bombas Autocebantes Serie GS",
    code: "",
    category: "Bombas Superficie",
    subcategory: "AUTOCEBANTES",
    downLoad: pdf1,
  },
  {
    _id: "surcatindbom002a",
    image: surfaceproduct2,
    title: "Bomba Centrífuga Serie AG",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Caracol",
    downLoad: pdf2,
  },
  {
    _id: "surcatindbom002b",
    image: surfaceproduct3,
    title: "Bomba Centrífuga Horizontal Serie D",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Caracol",
    downLoad: pdf3,
  },
  {
    _id: "surcatindbom002c",
    image: surfaceproduct4,
    title: "Electrobombas Centrífugas Serie BIG",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Caracol",
    downLoad: pdf4,
  },
  {
    _id: "surcatindbom003a",
    image: surfaceproduct5,
    title: "Carcasa partida HD Serie 340",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Carscasa Partida",
    downLoad: pdf5,
  },
  {
    _id: "surcatindbom004a",
    image: surfaceproduct6,
    title: "Bombas Verticales Multietapas Serie CVI",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Multietapa",
    downLoad: pdf6,
  },
  {
    _id: "surcatindbom004b",
    image: surfaceproduct7,
    title: "Bombas Verticales Multietapas Serie VR",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Multietapa",
    downLoad: pdf7,
  },
  {
    _id: "surcatindbom004c",
    image: surfaceproduct8,
    title: "Bombas Centrífugas Multiecelulares Verticales Serie CR",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Multietapa",
    downLoad: pdf8,
  },
  {
    _id: "surcatindbom004d",
    image: surfaceproduct9,
    title: "Bombas Centrífugas Multietapas en línea Serie VPS",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Multietapa",
    downLoad: pdf9,
  },
  {
    _id: "surcatindbom005a",
    image: surfaceproduct10,
    title: "Bombas Normadas con Caja de Baleros Serie FIT",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Tipo ANSI",
    downLoad: pdf10,
  },
  {
    _id: "surcatindbom005b",
    image: surfaceproduct11,
    title: "OSD de la Serie 460",
    code: "",
    category: "Bombas Superficie",
    subcategory: "Tipo ANSI",
    downLoad: pdf11,
  },
  {
    _id: "surcatindmotbal005a",
    image: surfaceproduct12,
    title: "Acoplamiento JM",
    code: "",
    category: "Motores Superficie",
    subcategory: "BALDOR",
    downLoad: "none",
  },
  {
    _id: "surcatindmotbal005b",
    image: surfaceproduct13,
    title: "Acoplamiento Varios JM",
    code: "",
    category: "Motores Superficie",
    subcategory: "BALDOR",
    downLoad: "none",
  },
  {
    _id: "surcatindmotgl005a",
    image: surfaceproduct14,
    title: "Motores de Bombas Verticales NEMA Premium",
    code: "",
    category: "Motores Superficie",
    subcategory: "GENERAL ELECTRIC",
    downLoad: pdf14,
  },
  {
    _id: "surcatindmotgl005b",
    image: surfaceproduct15,
    title: "Motores de uso general premium NEMA",
    code: "",
    category: "Motores Superficie",
    subcategory: "GENERAL ELECTRIC",
    downLoad: pdf15,
  },
];

export default surfaceIndustrialProductList;
