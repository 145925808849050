import fiberglass from "../../images/productsImages/tankImages/fiberglass.jpg";
import horizontal from "../../images/productsImages/tankImages/horizontal.jpg";
import vertical from "../../images/productsImages/tankImages/vertical.jpg";
import fiberglassTank from "../../images/productsImages/tankImages/fiberglassTank.jpg";
import arvek from "../../images/productsImages/tankImages/ARVEK.png";
import fpshorizontal from "../../images/productsImages/tankImages/fps.png";
import gwshorizontal from "../../images/productsImages/tankImages/GWSPWN.jpg";
import fps from "../../images/productsImages/tankImages/FPS.jpg";
import gwschallenger from "../../images/productsImages/tankImages/CHALLENGER.jpg";
import gwspwn from "../../images/productsImages/tankImages/PWN.jpg";
import fiberglassIcon from "../../images/productsImages/tankImages/fiberglassIcon.svg";
import verticalIcon from "../../images/productsImages/tankImages/verticalIcon.svg";
import horizontalIcon from "../../images/productsImages/tankImages/horizontalIcon.svg";
import pdf1 from "../../pdf/tank/fiberglass.pdf";
import pdf2 from "../../pdf/tank/TANKARVEK.pdf";
import pdf3 from "../../pdf/tank/FPShorizontal.pdf";
import pdf4 from "../../pdf/tank/PressureWave_ES.pdf";
import pdf5 from "../../pdf/tank/FPSvertical.pdf";
import pdf6 from "../../pdf/tank/challenger.pdf";
import pdf7 from "../../pdf/tank/PressureWave_ES.pdf";

const fiberglassList = [
  {
    _id: "tanFib001",
    image: fiberglassTank,
    title: "Global Water Solutions",
    code: "C2LITE CAD",
    downLoad: pdf1,
  },
];
const horizontalMetallicList = [
  {
    _id: "tanHor001",
    image: arvek,
    title: "Tanque ARVEK",
    code: "C2LITE CAD",
    downLoad: pdf2,
  },
  {
    _id: "tanHor002",
    image: fpshorizontal,
    title: "Tanque Horizontal Hidroneumático FPS",
    code: "FPS",
    downLoad: pdf3,
  },
  {
    _id: "tanHor003",
    image: gwshorizontal,
    title: "PressureWave Tanque Horizontal",
    code: "GWS PWN",
    downLoad: pdf4,
  },
];

const verticalMetallicList = [
  {
    _id: "tanVer001",
    image: fps,
    title: "Global Water Solutions",
    code: "FPS",
    downLoad: pdf5,
  },
  {
    _id: "tanVer002",
    image: gwschallenger,
    title: "GWS CHALLENGER",
    code: "GWS CHALLENGER",
    downLoad: pdf6,
  },
  {
    _id: "tanVer003",
    image: gwspwn,
    title: "GWS PressureWave",
    code: "GWS PWN",
    downLoad: pdf7,
  },
];

const tankList = [
  {
    _id: "tan001",
    image: fiberglass,
    title: "Fibra de Vidrio",
    link: "/products/tanks/fiberglass",
    list: fiberglassList,
    path: "fiberglass",
    icon: fiberglassIcon,
  },
  {
    _id: "tan002",
    image: horizontal,
    title: "Metálicos Horizontales",
    link: "/products/tanks/horizontalmetallic",
    list: horizontalMetallicList,
    path: "horizontalmetallic",
    icon: horizontalIcon,
  },
  {
    _id: "tan003",
    image: vertical,
    title: "Metálicos Verticales",
    link: "/products/tanks/verticalmetallic",
    list: verticalMetallicList,
    path: "verticalmetallic",
    icon: verticalIcon,
  },
];

export default tankList;
