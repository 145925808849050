import React from "react";
import Category from "../../components/UI/Category";
import pressureList from "../../Lists/products/pressureList";
import classes from "./Products.module.css";

const Pressure = () => {
  const title = "PRESIÓN CONSTANTE";
  return (
    <div className={classes.showStoreList}>
      <div className={classes.familyMainInfo}>
        <h1>Sistemas de Presión Constante</h1>
        <p>
          Contamos con gran variedad de sistemas de presión constante, ya sea
          importados directamente o ensamblados en nuestros talleres, para
          satisfacer necesidades pequeñas en casas de habitación o sistemas
          complejos para edificios, industria y hotelería
        </p>
      </div>
      <Category title={title} list={pressureList}></Category>
    </div>
  );
};

export default Pressure;
