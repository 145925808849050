import React from "react";
import ProductMenu from "../../components/UI/Productmenu";
import tankList from "../../Lists/products/tankList";
import classes from "./General.module.css";

const Tank = () => {
  return (
    <div className={classes.productMenuPage}>
      <div className={classes.productMainInfo}>
        <h1>Tanques Hidroneumáticos:</h1>
        <p>
          Contamos con tanques hidroneumáticos en gran variedad en tamaños,
          formas y materiales de construcción, algunos para uso en ambientes
          corrosivos y también para altas presiones.
        </p>
      </div>
      <ProductMenu list={tankList}></ProductMenu>
    </div>
  );
};

export default Tank;
