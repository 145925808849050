import React from "react";
import Productcard from "./Productcard";
import classes from "./Category.module.css";

const Category = (props) => {
  return (
    <section className={classes.store}>
      <h1>{props.title}</h1>
      <div className={classes.products}>
        {props.list.map((product) => (
          <Productcard
            id={product._id}
            key={product._id}
            productimg={product.image}
            productitle={product.title}
            productDownLoad={product.downLoad}
          ></Productcard>
        ))}
      </div>
    </section>
  );
};

export default Category;
