import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Pool from "./pages/Products/Pool";
import Pressure from "./pages/Products/Pressure";
import Firesystems from "./pages/Products/Firesystems";
import Hydropneumatic from "./pages/Products/Hydropneumatic";
import Submersible from "./pages/Products/Submersible";
import Surface from "./pages/Products/Surface";
import Tank from "./pages/Products/Tank";
import Panel from "./pages/Products/Panel";
import Layout from "./layout/Layout";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import Brand from "./pages/Brand";
import Gallery from "./pages/Gallery";
import Service from "./pages/Service";
import ScrollTop from "./layout/ScrollTop";
import TanksProducts from "./pages/Products/tankProducts/TanksProducts";
import Poolproducts from "./pages/Products/poolProducts/Showpoolproducts";
import SubmersibleProducts from "./pages/Products/SubmersibleProducts/submersibleProducts";
import SurfaceProducts from "./pages/Products/surfaceProduts/surfaceProducts";

function App() {
  return (
    <Layout>
      <ScrollTop>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/products/pool">
            <Pool />
          </Route>
          <Route exact path="/products/pool/:poolId">
            <Poolproducts />
          </Route>
          <Route exact path="/products/pressure">
            <Pressure />
          </Route>
          <Route exact path="/products/firesystem">
            <Firesystems />
          </Route>
          <Route exact path="/products/hydropneumatic">
            <Hydropneumatic />
          </Route>
          <Route exact path="/products/submersible">
            <Submersible />
          </Route>
          <Route exact path="/products/submersible/:submersibleId">
            <SubmersibleProducts />
          </Route>
          <Route exact path="/products/surface">
            <Surface />
          </Route>
          <Route exact path="/products/surface/:surfaceId">
            <SurfaceProducts />
          </Route>
          <Route exact path="/products/tanks">
            <Tank />
          </Route>
          <Route exact path="/products/tanks/:tankId">
            <TanksProducts />
          </Route>
          <Route exact path="/products/panel">
            <Panel />
          </Route>
          <Route exact path="/services/:servicesId">
            <Service />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route exact path="/brand">
            <Brand />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </ScrollTop>
    </Layout>
  );
}

export default App;
