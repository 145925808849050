import React from "react";
import classes from "./Template.module.css";
import { Link } from "react-router-dom";

const Template = (props) => {
  return (
    <div className={`${classes.pageCard} ${props.className}`}>
      <div className={classes.textInfo}>
        <h1>{props.title}</h1>
        <h4>{props.text2}</h4>
        <p>{props.text}</p>
        <Link type="button" className="btn btn-primary" to="/contact">
          {props.buttonName}
        </Link>
        {props.children}
      </div>
      <div>
        <img
          src={props.image}
          className={classes.templateImg}
          alt="service"
        ></img>
      </div>
    </div>
  );
};

export default Template;
