import React from "react";
import { useParams } from "react-router-dom";
import Template from "../components/UI/Template";
import servicesList from "../Lists/servicesList";
import {
  salesEmployeeList,
  engineeringEmployeeList,
  adrian,
  victor,
} from "../Lists/employeeList";
import classes from "./Service.module.css";
import EmployeeCard from "../components/UI/EmployeeCard";

const Service = () => {
  const chief = adrian;
  const official = victor;
  const params = useParams();
  const service = servicesList.find(
    (service) => service.path === params.servicesId
  );

  if (!service) {
    return <p>No service found</p>;
  }
  const handlepersonal = () => {
    if (service.path === "advice") {
      return (
        <section className={classes.employeeSection}>
          <div className={classes.principal}>
            <h1>Asesores</h1>
            <h4>Conozca a nuestros equipo de ventas</h4>
            <EmployeeCard
              key={chief._id}
              name={chief.name}
              number={chief.phoneNumber}
              email={chief.email}
              photo={chief.image}
            ></EmployeeCard>
          </div>
          <div className={classes.employees}>
            {salesEmployeeList.map((employee) => (
              <EmployeeCard
                key={employee._id}
                name={employee.name}
                number={employee.phoneNumber}
                email={employee.email}
                photo={employee.image}
              ></EmployeeCard>
            ))}
          </div>
        </section>
      );
    }
    if (service.path === "engineering") {
      return (
        <section className={classes.employeeSection}>
          <h1>Profesionales</h1>
          <h4>Conozca a nuestros equipo de ingeniería y diseño</h4>
          <div className={classes.employees}>
            {engineeringEmployeeList.map((employee) => (
              <EmployeeCard
                key={employee._id}
                name={employee.name}
                number={employee.phoneNumber}
                email={employee.email}
                photo={employee.image}
              ></EmployeeCard>
            ))}
          </div>
        </section>
      );
    } else if (service.path === "maintenance") {
      return (
        <section className={classes.employeeSection}>
          <div className={classes.principal}>
            <h1>Ingeniero</h1>
            <h4>Conozca a nuestro funcionario de mantenimiento</h4>
            <EmployeeCard
              key={official._id}
              name={official.name}
              number={official.phoneNumber}
              email={official.email}
              photo={official.image}
            ></EmployeeCard>
          </div>
        </section>
      );
    }
  };
  return (
    <>
      <Template
        title={service.title}
        image={service.imageLeft}
        text2={service.text}
        text={service.paragraph}
        buttonName="Contáctenos"
      ></Template>
      {handlepersonal()}
    </>
  );
};

export default Service;
