import React from "react";
import { useParams } from "react-router-dom";
import Category from "../../../components/UI/Category";
import tankList from "../../../Lists/products/tankList";
import classes from "../Products.module.css";

const TanksProducts = () => {
  const params = useParams();
  const store = tankList.find((store) => store.path === params.tankId);

  if (!store) {
    return <p>No products were found matching your selection</p>;
  }
  return (
    <div className={classes.showStoreList}>
      <div className={classes.tankFamilyMainInfo}>
        <Category title={store.title} list={store.list}></Category>
      </div>
    </div>
  );
};

export default TanksProducts;
