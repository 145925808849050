import industrial from "../../images/productsImages/submersibleImages/industrial.jpg";
import residential from "../../images/productsImages/submersibleImages/residencial.jpg";
import residentialIcon from "../../images/productsImages/surfaceImages/residencialIcon.svg";
import industrialIcon from "../../images/productsImages/surfaceImages/industrialIcon.svg";

const fe = "FRANKLIN ELECTRIC";

const surIndustrialList = [
  {
    _id: "surcatind001",
    title: "Bombas Superficie",
    subcategory: [
      { name: "AUTOCEBANTES", _id: "surcatindbomaut001" },
      { name: "Caracol", _id: "surcatindbomcar001" },
      { name: "Carscasa Partida", _id: "surcatindbomcap001" },
      { name: "Multietapa", _id: "surcatindbommul001" },
      { name: "Tipo ANSI", _id: "surcatindbomtip001" },
    ],
  },
  {
    _id: "surcatind002",
    title: "Motores Superficie",
    subcategory: [
      { name: "BALDOR", _id: "surcatindmotbal001" },
      { name: "GENERAL ELECTRIC", _id: "surcatindmotLGl001" },
    ],
  },
];
const surResidencialList = [
  {
    _id: "surcatres001",
    title: "Autocebantes",
  },
  {
    _id: "surcatres002",
    title: "Caracol",
  },
  {
    _id: "surcatres003",
    title: "Combustible",
  },
  {
    _id: "surcatres004",
    title: "Jet",
    subcategory: [
      { name: fe, _id: "surcatresjetfe001" },
      { name: "PEARL", _id: "surcatresjetpearl001" },
    ],
  },
  {
    _id: "surcatres005",
    title: "Multietapa",
    subcategory: [
      { name: fe, _id: "surcatresmulfe001" },
      { name: "LITTLE GIANT", _id: "surcatresmullg001" },
      { name: "PEARL", _id: "surcatresmulpearl001" },
    ],
  },
  {
    _id: "surcatres006",
    title: "Periférica",
    subcategory: [
      { name: "LITTLE GIANT", _id: "surcatresperlg001" },
      { name: "PEARL", _id: "surcatresperpearl001" },
    ],
  },
];

const surfaceList = [
  {
    _id: "sur001",
    image: industrial,
    title: "Industrial",
    link: "/products/surface/industrial",
    list: surIndustrialList,
    path: "industrial",
    icon: industrialIcon,
  },
  {
    _id: "sur002",
    image: residential,
    title: "Residencial",
    link: "/products/surface/residential",
    list: surResidencialList,
    path: "residential",
    icon: residentialIcon,
  },
];

export { surfaceList };
