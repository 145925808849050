import poolproduct1 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/BFSERIESIMAGE.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/PGSERIESIMAGE.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/filter/hidrotermal/VSERIESIMAGE.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/filter/pentair/CLEAN&CLEAR.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/filter/pentair/SANDDOLAR.jpg";
import poolproduct6 from "../../../images/productsImages/poolImages/products/filter/pentair/TAGELUS.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/filter/pentair/TRITON.jpg";

import pdf2 from "../../../pdf/pool/filter/HT-PG.pdf";
import pdf3 from "../../../pdf/pool/filter/VSERIES.pdf";
import pdf4 from "../../../pdf/pool/filter/CLEAN&CLEAR.pdf";
import pdf5 from "../../../pdf/pool/filter/SANDDOLAR.pdf";
import pdf6 from "../../../pdf/pool/filter/TAGELUS.pdf";

const poolFilterProductList = [
  {
    _id: "poocatfilhidro001a",
    image: poolproduct1,
    title: "Filtro BF Series",
    code: "HT-BF",
    category: "HIDROTERMAL",
    subcategory: "HT-BF",
    downLoad: "none",
  },
  {
    _id: "poocatfilhidro002a",
    image: poolproduct2,
    title: "Moldeado por Soplado Filtro de Arena PG Series",
    code: "HT-PG",
    category: "HIDROTERMAL",
    subcategory: "HT-PG",
    downLoad: pdf2,
  },
  {
    _id: "poocatfilhidro003a",
    image: poolproduct3,
    title: "Fribra de Vidrio Filtro de Arena V Series",
    code: "HT-V",
    category: "HIDROTERMAL",
    subcategory: "HT-V",
    downLoad: pdf3,
  },
  {
    _id: "poocatfilpent001a",
    image: poolproduct4,
    title: "Filtro de Cartucho",
    code: "",
    category: "PENTAIR",
    subcategory: "CLEAN AND CLEAR",
    downLoad: pdf4,
  },
  {
    _id: "poocatfilpent002a",
    image: poolproduct5,
    title: "Filtro de Arena de Montaje Superior SAND DOLLAR",
    code: "",
    category: "PENTAIR",
    subcategory: "SAND DOLLAR",
    downLoad: pdf5,
  },
  {
    _id: "poocatfilpent003a",
    image: poolproduct6,
    title: "Filtro de Arena de Fibra de Vidrio TAGELUS",
    code: "",
    category: "PENTAIR",
    subcategory: "TAGELUS",
    downLoad: pdf6,
  },
  {
    _id: "poocatfilpent004a",
    image: poolproduct7,
    title: "Filtro de Arena de Fibra de Arena TRITON II",
    code: "",
    category: "PENTAIR",
    subcategory: "/pool/filter/TRITON.pdf",
    downLoad: "none",
  },
];

export default poolFilterProductList;
