import poolproduct1 from "../../../images/productsImages/poolImages/products/heating/hidrotermal/Calentadorenlinea.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/heating/hidrotermal/HSeries.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/heating/hidrotermal/Triclorotableta-02.jpg";
import poolproduct4 from "../../../images/productsImages/poolImages/products/heating/pentair/heatingPentair.jpg";
import poolproduct5 from "../../../images/productsImages/poolImages/products/heating/pentair/UltraTemp.jpg";

import pdf2 from "../../../pdf/pool/heating/HSERIES.pdf";
import pdf3 from "../../../pdf/pool/heating/InverterPlusTop.pdf";
import pdf4 from "../../../pdf/pool/heating/mastertemp.pdf";
import pdf5 from "../../../pdf/pool/heating/UltraTemp.pdf";

const poolHeatingProductList = [
  {
    _id: "poocatheahidro001a",
    image: poolproduct1,
    title: "Calentador en lÍnea",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Calentador en Línea",
    downLoad: "none",
  },
  {
    _id: "poocatheahidro002a",
    image: poolproduct2,
    title: "H Series",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "H Series",
    downLoad: pdf2,
  },
  {
    _id: "poocatheahidro003a",
    image: poolproduct3,
    title: "Inverter Plus Top",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Inverter Plus Top",
    downLoad: pdf3,
  },
  {
    _id: "poocatheapent001a",
    image: poolproduct4,
    title: "MASTERTEMP Calentador de Alto Rendimiento",
    code: "",
    category: "PENTAIR",
    subcategory: "MASTERTEMP",
    downLoad: pdf4,
  },
  {
    _id: "poocatheapent002a",
    image: poolproduct5,
    title: "ULTRATEMP Bomba de Calor de Alto Rendimiento",
    code: "",
    category: "PENTAIR",
    subcategory: "ULTRATEMP",
    downLoad: pdf5,
  },
];

export default poolHeatingProductList;
