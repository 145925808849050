import React from "react";
import alarm from "../../images/productsImages/alarm.jpg";
import classes from "./Products.module.css";
import Template from "../../components/UI/Template";
const Firesystems = () => {
  const text1 =
    "Bombas de agua de tipo turbina vertical, carcaza partida y succión final para protección contra incendios. Bombas listadas por UL y aprobadas por FM. Disponible con accionamiento diésel o eléctrico.";
  const text2 =
    "Damos servicio a edificios comerciales, municipales y residenciales de gran altura, grandes instalaciones industriales, almacenes, aeropuertos y cualquier instalación que por normativa requiera de un sistema húmedo de protección contra incendios. Igualmente suplimos e instalamos sistema de alarmas de incendios. Contamos con profesionales certificados en la normativo NFPA";
  return (
    <Template
      title="SISTEMAS CONTRA INCENDIOS"
      image={alarm}
      text2={text1}
      text={text2}
      buttonHandler={""}
      buttonName="Contáctenos"
      className={classes.fireSystem}
    ></Template>
  );
};

export default Firesystems;
