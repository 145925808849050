import React from "react";
const NotFound = () => {
  return (
    <div>
      <h1>NotFound</h1>
      <p>
        No pudimos encontrar la página que buscabas. Esto puede ser porque: Hay
        un error en la URL que escribiste en el navegador web. Comprueba la URL
        e inténtalo de nuevo. La página que estás buscando cambió de ubicación o
        fue eliminada. Para volver a tu página de inicio haz clic aquí, o puedes
        intentar buscar el contenido que deseas aquí.
      </p>
    </div>
  );
};

export default NotFound;
