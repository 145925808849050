import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OS.jpg";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OSC.jpg";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/OSG.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/SerieFWS.png";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/20E.jpg";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/20S.jpg";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/Industrial/AguasNegras/WSEFFLUENT.jpg";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/Industrial/AltaEficiencia/AltaEficiencia.png";
import submersibleproduct9 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/FE/SSI.jpg";
import submersibleproduct10 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/FE/STS.jpg";
import submersibleproduct11 from "../../../images/productsImages/submersibleImages/products/Industrial/BombasSumergibles/PEARL/PWS.jpg";
import submersibleproduct12 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/NEMA6FOTO.jpg";
import submersibleproduct13 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/Nema8.jpg";
import submersibleproduct14 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/Brochure-Motores-Rebobinables-01.png";
import submersibleproduct15 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/6MRCP-MRSP.png";
import submersibleproduct16 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/7MRCP-01.png";
import submersibleproduct17 from "../../../images/productsImages/submersibleImages/products/Industrial/MotoresSumergibles/8MRCP-MRSP-01.png";
import pdf1 from "../../../pdf/submersible/industrial/os.pdf";
import pdf2 from "../../../pdf/submersible/industrial/OSC.pdf";
import pdf3 from "../../../pdf/submersible/industrial/osg.pdf";
import pdf4 from "../../../pdf/submersible/industrial/FWS.pdf";
import pdf5 from "../../../pdf/submersible/industrial/20e.pdf";
import pdf6 from "../../../pdf/submersible/industrial/20s.pdf";
import pdf7 from "../../../pdf/submersible/industrial/ws.pdf";
import pdf8 from "../../../pdf/submersible/industrial/AltaEficiencia.pdf";
import pdf9 from "../../../pdf/submersible/industrial/SSI.pdf";
import pdf10 from "../../../pdf/submersible/industrial/STS.pdf";
import pdf11 from "../../../pdf/submersible/industrial/PWS.pdf";
import pdf12 from "../../../pdf/submersible/industrial/NEMA6.pdf";
import pdf13 from "../../../pdf/submersible/industrial/NEMA8.pdf";
import pdf14 from "../../../pdf/submersible/industrial/Rebobinables.pdf";
import pdf15 from "../../../pdf/submersible/industrial/6MRCP-MRSP.pdf";
import pdf16 from "../../../pdf/submersible/industrial/7MRCP.pdf";
import pdf17 from "../../../pdf/submersible/industrial/8MRCP-MRSP.pdf";

const fe = "FRANKLIN ELECTRIC";
const submersibleIndustrialProductList = [
  {
    _id: "subcatindagubgp001a",
    image: submersibleproduct1,
    title: "Bomba Sumergible BGP-OS",
    code: "BGP-OS",
    category: "Aguas Negras",
    subcategory: "BGP",
    downLoad: pdf1,
  },
  {
    _id: "subcatindagubgp001b",
    image: submersibleproduct2,
    title: "Bomba Sumergible BGP-OSC",
    code: "BGP-OSC",
    category: "Aguas Negras",
    subcategory: "BGP",
    downLoad: pdf2,
  },
  {
    _id: "subcatindagubgp001c",
    image: submersibleproduct3,
    title: "Bomba Sumergible BGP-OSG",
    code: "BGP-OSG",
    category: "Aguas Negras",
    subcategory: "BGP",
    downLoad: pdf3,
  },
  {
    _id: "subcatindagufe001a",
    image: submersibleproduct4,
    title: "Bombas Sumergibles para Aguas Negras Serie FWS",
    code: "FPS",
    category: "Aguas Negras",
    subcategory: fe,
    downLoad: pdf4,
  },
  {
    _id: "subcatindaguLG001a",
    image: submersibleproduct5,
    title: "Bombas Efluentes Serie 20E 2 HP",
    code: "20E",
    category: "Aguas Negras",
    subcategory: "LITTLE GIANT",
    downLoad: pdf5,
  },
  {
    _id: "subcatindaguLG001b",
    image: submersibleproduct6,
    title: "Bombas para Aguas Negras Serie 20S",
    code: "20S",
    category: "Aguas Negras",
    subcategory: "LITTLE GIANT",
    downLoad: pdf6,
  },
  {
    _id: "subcatindaguLG001c",
    image: submersibleproduct7,
    title: "Bombas para Aguas Negras Serie WS",
    code: "WS",
    category: "Aguas Negras",
    subcategory: "LITTLE GIANT",
    downLoad: pdf7,
  },
  {
    _id: "subcatindalt001a",
    image: submersibleproduct8,
    title: "Sistema de Bombeo de Alta Eficiencia",
    code: "",
    category: "Alta Eficiencia",
    subcategory: fe,
    downLoad: pdf8,
  },
  {
    _id: "subcatindbomFE001a",
    image: submersibleproduct9,
    title: "Bomba de Acero Inoxidable Serie SSI",
    code: "FE-SSI",
    category: "Bombas Sumergibles",
    subcategory: fe,
    downLoad: pdf9,
  },
  {
    _id: "subcatindbomFE001b",
    image: submersibleproduct10,
    title: "Bombas Turbina Sumergibles Serie STS",
    code: "FE-STS",
    category: "Bombas Sumergibles",
    subcategory: fe,
    downLoad: pdf10,
  },
  {
    _id: "subcatindbompearl001a",
    image: submersibleproduct11,
    title: "Bombas Sumergibles de Acero Inoxidable PWS",
    code: "",
    category: "Bombas Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf11,
  },
  {
    _id: "subcatindmotFEl001a",
    image: submersibleproduct12,
    title: `MOTOR SAND FIGHTER DE 6"`,
    code: "",
    category: "Motores Sumergibles",
    subcategory: fe,
    downLoad: pdf12,
  },
  {
    _id: "subcatindmotFEl001b",
    image: submersibleproduct13,
    title: `Motores Sumergibles Encapsulados de 8"`,
    code: "",
    category: "Motores Sumergibles",
    subcategory: fe,
    downLoad: pdf13,
  },
  {
    _id: "subcatindmotFEl001c",
    image: submersibleproduct14,
    title: `Motores Sumergibles Rebobinables de 8", 10" y 12"`,
    code: "",
    category: "Motores Sumergibles",
    subcategory: fe,
    downLoad: pdf14,
  },
  {
    _id: "subcatindmotPEARLl001a",
    image: submersibleproduct15,
    title: `Motores Sumergibles Rebobinables 6MRCP-6MRSP”`,
    code: "6MRCP-6MRSP",
    category: "Motores Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf15,
  },
  {
    _id: "subcatindmotPEARLl001b",
    image: submersibleproduct16,
    title: `Motores Sumergibles Rebobinables 7MRCP`,
    code: "7MRCP",
    category: "Motores Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf16,
  },
  {
    _id: "subcatindmotPEARLl001c",
    image: submersibleproduct17,
    title: `Motores Sumergibles Rebobinables 8MRCP-8MRSP`,
    code: "8MRCP-8MRSP",
    category: "Motores Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf17,
  },
];

export default submersibleIndustrialProductList;
