import photo1 from "../images/employee/jacksonAbarca.jpg";
import photo2 from "../images/employee/josetteAlvarado.jpg";
import photo3 from "../images/employee/sebastianCarrillo.jpg";
import photo4 from "../images/employee/alejandroHernandez.jpg";
import photo5 from "../images/employee/alejandroRamirez.jpg";
import photo6 from "../images/employee/luisRojas.jpg";
import photo7 from "../images/employee/oscarRojas.jpg";
import photo8 from "../images/employee/adrianUrena.jpg";
import photo9 from "../images/employee/icono.png";
import photo10 from "../images/employee/greivinVasquez.jpg";
import photo11 from "../images/employee/pabloVasquez.jpg";
import photo12 from "../images/employee/francisoVestita.jpg";
import photo13 from "../images/employee/edwinVindas.jpg";
import photo14 from "../images/employee/ignacioZuniga.jpg";
import photo15 from "../images/employee/icono.png";

const adrian = {
  _id: "emp008",
  image: photo8,
  name: "Adrian Ureña",
  phoneNumber: "8485-4502",
  email: "aurena@alrotek.com",
};
const rene = {
  _id: "emp0015",
  image: photo15,
  name: "René Alfaro Martínez.",
  phoneNumber: "8431-7179",
  email: "operaciones1@alrotek.com",
};

const josette = {
  _id: "emp002",
  image: photo2,
  name: "Ing.Josette Alvarado",
  phoneNumber: "8662-0307",
  email: "jmalvarado@alrotek.com",
};
const luis = {
  _id: "emp006",
  image: photo6,
  name: "Ing. Luis Javier Rojas",
  phoneNumber: "8987-9756",
  email: "lrojas@alrotek.com",
};
const oscar = {
  _id: "emp007",
  image: photo7,
  name: "Ing.Oscar Rojas",
  phoneNumber: "8394-5444",
  email: "orojas@alrotek.com",
};
const victor = {
  _id: "emp009",
  image: photo9,
  name: "Ing. Victor Varela",
  phoneNumber: "8302-8060",
  email: "vvarela@alrotek.com",
};
const pablo = {
  _id: "emp011",
  image: photo11,
  name: "Ing. Pablo Vásquez",
  phoneNumber: "8301-4432",
  email: "jvasquez@alrotek.com",
};
const francisco = {
  _id: "emp012",
  image: photo12,
  name: "Francisco Vestita",
  phoneNumber: "8494-6450",
  email: "fvestita@alrotek.com",
};
const ignacio = {
  _id: "emp014",
  image: photo14,
  name: "Ing.Ignacio Zúñiga",
  phoneNumber: "8370-9696",
  email: "izuniga@alrotek.com",
};

const salesEmployeeList = [
  {
    _id: "emp001",
    image: photo1,
    name: "Jackson Abarca",
    phoneNumber: "8302-1517",
    email: "jabarca@alrotek.com",
  },
  josette,
  {
    _id: "emp003",
    image: photo3,
    name: "Sebastian Carrillo",
    phoneNumber: "8521-0028",
    email: "scarrillo@alrotek.com",
  },
  {
    _id: "emp004",
    image: photo4,
    name: "Alejandro Hernández",
    phoneNumber: "8370-7541",
    email: "ahernandez@alrotek.com ",
  },
  {
    _id: "emp005",
    image: photo5,
    name: "Alejandro Ramírez",
    phoneNumber: "8304-2882",
    email: "aramirez@alrotek.com",
  },
  luis,
  oscar,
  {
    _id: "emp010",
    image: photo10,
    name: "Greivin Vásquez",
    phoneNumber: "8494-6718",
    email: "gvasquez@alrotek.com",
  },
  pablo,
  francisco,
  {
    _id: "emp013",
    image: photo13,
    name: "Edwin Vindas",
    phoneNumber: "8351-7240",
    email: "evindas@alrotek.com",
  },
  ignacio,
];

const engineeringEmployeeList = [
  josette,
  luis,
  oscar,
  pablo,
  francisco,
  ignacio,
];

export { salesEmployeeList, engineeringEmployeeList, adrian, victor, rene };
