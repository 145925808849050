import lpz from "../../images/productsImages/hydroneumaticImages/LPZ.png";
import ljp from "../../images/productsImages/hydroneumaticImages/SISTEMAHIDRONEUMÁTICOSERIELJP.jpg";
import pg from "../../images/productsImages/hydroneumaticImages/SERIEPG.jpg";
import pdf1 from "../../pdf/hydroneumatic/Serie-LPZ.pdf";
import pdf2 from "../../pdf/hydroneumatic/Serie-LJP.pdf";
import pdf3 from "../../pdf/hydroneumatic/Serie-PG.pdf";

const hydroneumaticList = [
  {
    _id: "hyd001",
    image: lpz,
    title: "Serie LPZ",
    code: "",
    downLoad: pdf1,
  },
  {
    _id: "hyd002",
    image: ljp,
    title: "Sistema Hidroneumático Serie LJP",
    code: "",
    downLoad: pdf2,
  },
  {
    _id: "hyd003",
    image: pg,
    title: "Sistema Hidroneumático Serie PG",
    code: "",
    downLoad: pdf3,
  },
];

export default hydroneumaticList;
