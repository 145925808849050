import poolproduct1 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/14plgprofesional.jpg";
import poolproduct2 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/14pulgadas.jpg";
import poolproduct3 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/16plgconcepillo.jpg";
import poolproduct4 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Deluxe.jpg";
import poolproduct5 from "../../../images/productsImages/poolImages/products/cleaning/pentair/8ruedas.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/TPCDELUXESILVER.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/TPCSILVER.jpg";
import poolproduct8 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/10pulgadas.jpg";
import poolproduct9 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/18pulgadasplastico.jpg";
import poolproduct10 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/CEPILLO10CERDASPLASTICAS.jpg";
import poolproduct11 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/CEPILLO18CERDASACERO.jpg";
import poolproduct12 from "../../../images/productsImages/poolImages/products/cleaning/pentair/9pulgadasaceroinoxidable.jpg";
import poolproduct13 from "../../../images/productsImages/poolImages/products/cleaning/pentair/18pulgadasaceroinox.jpg";
import poolproduct14 from "../../../images/productsImages/poolImages/products/cleaning/pentair/18pulgadasplastico.png";
import poolproduct15 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/MANGUERA.jpg";
import poolproduct16 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Manguerapremium.jpg";
import poolproduct17 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Bolsa1.jpg";
import poolproduct18 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Bolsa2.jpg";
import poolproduct19 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Plano1.jpg";
import poolproduct20 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Plano2.jpg";
import poolproduct21 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Plano3.jpg";
import poolproduct22 from "../../../images/productsImages/poolImages/products/cleaning/pentair/recogehojasespatula.jpg";
import poolproduct23 from "../../../images/productsImages/poolImages/products/cleaning/pentair/recojehojasbolsa.jpg";
import poolproduct24 from "../../../images/productsImages/poolImages/products/cleaning/pentair/recojehojasplano.jpg";
import poolproduct25 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal/Crayfish.png";
import poolproduct26 from "../../../images/productsImages/poolImages/products/cleaning/hidrotermal//Solefish.png";
import poolproduct27 from "../../../images/productsImages/poolImages/products/cleaning/pentair/prowler920-cleaner.png";

const poolcleaningProductsList = [
  {
    _id: "poocatclehidro001a",
    image: poolproduct1,
    title: `Aspiradora de 14" profesional`,
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Aspiradora",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro001b",
    image: poolproduct2,
    title: `Aspiradora de 14"`,
    code: "CE-VH10",
    category: "HIDROTERMAL",
    subcategory: "Aspiradora",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro001c",
    image: poolproduct3,
    title: `Aspiradora de 16" con cepillo`,
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Aspiradora",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro001d",
    image: poolproduct4,
    title: "Aspiradora deluxe",
    code: "CE-VH30-2",
    category: "HIDROTERMAL",
    subcategory: "Aspiradora",
    downLoad: "none",
  },
  {
    _id: "poocatclepent001a",
    image: poolproduct5,
    title: "Aspiradora de 8 ruedas",
    code: "",
    category: "PENTAIR",
    subcategory: "Aspiradora",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro002a",
    image: poolproduct6,
    title: "Barra TPC Silver Deluxe",
    code: "TPC",
    category: "HIDROTERMAL",
    subcategory: "Barra Telescopica",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro002b",
    image: poolproduct7,
    title: "Barra TPC Silver",
    code: "TPC",
    category: "HIDROTERMAL",
    subcategory: "Barra Telescopica",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro003a",
    image: poolproduct8,
    title: `Cepillo de 10" Acero Inoxidable`,
    code: "CE-B30-S",
    category: "HIDROTERMAL",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro003b",
    image: poolproduct9,
    title: `Cepillo de 18" de Plástico`,
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro003c",
    image: poolproduct10,
    title: `Cepillo de 10" Cuerdas Plásticas"`,
    code: "CE-B30",
    category: "HIDROTERMAL",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro003d",
    image: poolproduct11,
    title: `Cepillo de 18" Cuerdas Acero`,
    code: "CE-B20-S",
    category: "HIDROTERMAL",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclepent002a",
    image: poolproduct12,
    title: `Cepillo de 9" de Acero Inoxidable`,
    code: "",
    category: "PENTAIR",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclepent002b",
    image: poolproduct13,
    title: `Cepillo de 18" de Acero Inoxidable`,
    code: "",
    category: "PENTAIR",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclepent002c",
    image: poolproduct14,
    title: `Cepillo de 18" de Plástico`,
    code: "",
    category: "PENTAIR",
    subcategory: "Cepillos",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro004a",
    image: poolproduct15,
    title: "Manguera",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Mangueras",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro004b",
    image: poolproduct16,
    title: "Manguera Premium",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Mangueras",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro005a",
    image: poolproduct17,
    title: "Recolector tipo Bolsa de Aluminio",
    code: "CE-BLS20",
    category: "HIDROTERMAL",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro005b",
    image: poolproduct18,
    title: "Recolector tipo Bolsa Plástico",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro005c",
    image: poolproduct19,
    title: "Recolector Plano Plástico",
    code: "CE-LF10",
    category: "HIDROTERMAL",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro005d",
    image: poolproduct20,
    title: "Recolector Plano Plástico",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro005e",
    image: poolproduct21,
    title: "Recolector Plano Aluminio",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclepent003a",
    image: poolproduct22,
    title: "Recolector tipo Bolsa",
    code: "",
    category: "PENTAIR",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclepent003b",
    image: poolproduct23,
    title: "Recolector tipo Bolsa Grande",
    code: "",
    category: "PENTAIR",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclepent003c",
    image: poolproduct24,
    title: "Recolector Plano",
    code: "",
    category: "PENTAIR",
    subcategory: "Recolector de hojas",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro006a",
    image: poolproduct25,
    title: "Robot Limpiador Crayfish",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Robots",
    downLoad: "none",
  },
  {
    _id: "poocatclehidro006b",
    image: poolproduct26,
    title: "Robot Limpiador Solefish",
    code: "",
    category: "HIDROTERMAL",
    subcategory: "Robots",
    downLoad: "none",
  },
  {
    _id: "poocatclepent004",
    image: poolproduct27,
    title: "Robot Limpiador Prowler 920",
    code: "",
    category: "PENTAIR",
    subcategory: "Robots",
    downLoad: "none",
  },
];

export default poolcleaningProductsList;
