import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import classes from "./WhatsApp.module.css";

const WhatsAppButton = () => {
  return (
    <div className={classes.whatsapp}>
      <a href="https://api.whatsapp.com/send?phone=83707541">
        <BsWhatsapp className={classes.icon} />
      </a>
    </div>
  );
};
export default WhatsAppButton;
