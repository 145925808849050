import React from "react";
import { brandsList, franklinLogo } from "../Lists/brands";
import classes from "./Brand.module.css";

const Brand = () => {
  const logoFE = franklinLogo;
  return (
    <div className={classes.brandPage}>
      <h1>Marcas</h1>
      <div className={classes.principalBrand}>
        <img src={logoFE.image} alt={logoFE.alt}></img>
      </div>
      <div className={classes.sectionBrand}>
        {brandsList.map((brand) => (
          <div className={classes.brand} key={brand._id}>
            <img src={brand.image} alt={brand.alt}></img>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brand;
