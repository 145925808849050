import React from "react";
import { useState } from "react";
import Newcarousel from "../components/UI/Carousel";
import poolSliderList from "../Lists/galleryLists/poolSliderList";
import fireSliderList from "../Lists/galleryLists/fireSliderList";
import hydroneumaticSliderList from "../Lists/galleryLists/hydroneumaticSliderList";
import controlSliderList from "../Lists/galleryLists/controlSliderList";
import submersibleSliderList from "../Lists/galleryLists/submersibleSliderList";
import surfaceSliderList from "../Lists/galleryLists/surfaceSliderList";
import wellSliderList from "../Lists/galleryLists/wellSliderList";
import sewageWaterSliderList from "../Lists/galleryLists/sewageWaterSliderList";
import poolIcon from "../images/galleryImages/icons/pool.svg";
import sewageIcon from "../images/galleryImages/icons/sewage-01.svg";
import hydropneumaticIcon from "../images/galleryImages/icons/hydropneumatic.svg";
import powerIcon from "../images/galleryImages/icons/power.svg";
import pressureIcon from "../images/galleryImages/icons/pressure.svg";
import sciIcon from "../images/galleryImages/icons/sci.svg";
import submersibleIcon from "../images/galleryImages/icons/submersible.svg";
import fire from "../images/galleryImages/icons/fire.svg";
import wellIcon from "../images/galleryImages/icons/well.svg";
import classes from "./Gallery.module.css";
import WindowImage from "../components/UI/WindowImage";

const Gallery = () => {
  const [imageIsShown, setImageIsShown] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const showImageHandler = (id) => {
    setImageIsShown(true);
    setSelectedImage(findImageHandler(id));
  };
  const error = {
    _id: "error 001",
    title: "No encontrado",
    text: "Lo sentimos la imagen no se encuentra a disposición",
  };

  const findImageHandler = (id) => {
    if (id.includes("submersible")) {
      const imageItem = submersibleSliderList.find((image) => image._id === id);
      return imageItem;
    }
    if (id.includes("surface")) {
      const imageItem = surfaceSliderList.find((image) => image._id === id);
      return imageItem;
    }
    if (id.includes("pool")) {
      const imageItem = poolSliderList.find((image) => image._id === id);
      return imageItem;
    }
    if (id.includes("hydroneumatic")) {
      const imageItem = hydroneumaticSliderList.find(
        (image) => image._id === id
      );
      return imageItem;
    }
    if (id.includes("fire")) {
      const imageItem = fireSliderList.find((image) => image._id === id);
      return imageItem;
    }
    if (id.includes("well")) {
      const imageItem = wellSliderList.find((image) => image._id === id);
      return imageItem;
    }
    if (id.includes("control")) {
      const imageItem = controlSliderList.find((image) => image._id === id);
      return imageItem;
    } else if (id.includes("sewage")) {
      const imageItem = sewageWaterSliderList.find((image) => image._id === id);
      return imageItem;
    } else return { error };
  };

  const hidenImageHandler = () => {
    setImageIsShown(false);
  };

  return (
    <section className={classes.galleryPage}>
      <Newcarousel
        title="Bombeo Sumergible"
        array={submersibleSliderList}
        image={submersibleIcon}
        className={classes.carouselblue}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Bombeo de Superficie"
        array={surfaceSliderList}
        image={sciIcon}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Equipos de Piscina"
        array={poolSliderList}
        image={poolIcon}
        className={classes.carouselblue}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Sistemas de Presión Hidroneumático"
        array={hydroneumaticSliderList}
        image={hydropneumaticIcon}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Sistemas Contra Incendio."
        array={fireSliderList}
        image={fire}
        className={classes.carouselblue}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Mantenimientos de Pozos"
        array={wellSliderList}
        image={wellIcon}
        onShowImage={showImageHandler}
      ></Newcarousel>
      <Newcarousel
        title="Control y Potencia"
        array={controlSliderList}
        image={powerIcon}
        onShowImage={showImageHandler}
        className={classes.carouselblue}
      ></Newcarousel>
      <Newcarousel
        title="Aguas Negras y Residuales"
        array={sewageWaterSliderList}
        image={sewageIcon}
        onShowImage={showImageHandler}
      ></Newcarousel>
      {imageIsShown && (
        <WindowImage object={selectedImage} onHideImage={hidenImageHandler} />
      )}
    </section>
  );
};

export default Gallery;
