import React from "react";
import ProductMenu from "../../components/UI/Productmenu";
import { surfaceList } from "../../Lists/products/surfaceList";
import classes from "./General.module.css";

const Surface = () => {
  return (
    <div className={classes.productMenuPage}>
      <div className={classes.productMainInfo}>
        <h1>Equipos de bombeo de superficie</h1>
        <p>
          En equipos de bombeo de superficie, tenemos gran cantidad de opciones
          que permiten atender casi todas las necesidades del mercado, desde
          pequeñas bombas para casas, hasta bombas de 50 HP para sistemas de
          bombeo residenciales o industriales, así como equipos especializados
          para agricultura y otras actividades más especializadas como bombas
          para tratamiento de aguas negras y residuales
        </p>
      </div>
      <ProductMenu list={surfaceList}></ProductMenu>
    </div>
  );
};

export default Surface;
