import React from "react";
import classes from "./EmployeeCard.module.css";

const EmployeeCard = (props) => {
  return (
    <div className={`${classes.employeeCard}`}>
      <div>
        <img
          src={props.photo}
          className={classes.employeePhoto}
          alt="employee"
        ></img>
      </div>
      <div className={classes.personalInfo}>
        <h5>{props.name}</h5>
        <p>{props.number}</p>
        <p>{props.email}</p>
      </div>
    </div>
  );
};

export default EmployeeCard;
