import React from "react";
import Category from "../../components/UI/Category";
import hydroneumaticList from "../../Lists/products/hydroNeumaticList";
import classes from "./Products.module.css";

const Hydropneumatic = () => {
  const title = "SISTEMAS DE PRESIÓN HIDRONEUMÁTICOS";
  return (
    <div className={classes.showStoreList}>
      <div className={classes.familyMainInfo}>
        <h1>Sistemas de presión hidroneumáticos:</h1>
        <p>
          Mantenemos en inventario permanente sistemas de presión
          hidroneumáticos para sus instalaciones en casas y pequeños edificios,
          tanto con bombas sumergibles que minimizan el ruido, como con bombas
          externas
        </p>
      </div>
      <Category title={title} list={hydroneumaticList}></Category>
    </div>
  );
};

export default Hydropneumatic;
