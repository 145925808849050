import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/accesories/BOSHART/CHECKPASCON.png";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/accesories/BOSHART/SELLOSDEPOZO.png";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/accesories/FLOMATIC/checkvertical.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/CAJASDEARRANQUE.png";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/PUMPTEC.png";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/accesories/FRANKLINELECTRIC/SUBMONITOR.jpg";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/accesories/KALAS/cable.JPG";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/accesories/PEARL/CAJASDEARRANQUE.png";
import pdf3 from "../../../pdf/submersible/accessories/checkVertical.pdf";
import pdf4 from "../../../pdf/submersible/accessories/cajasdecontrolFE.pdf";
import pdf5 from "../../../pdf/submersible/accessories/pumptec.pdf";
import pdf6 from "../../../pdf/submersible/accessories/SUBMONITORCONNECT.pdf";
import pdf7 from "../../../pdf/submersible/accessories/KalasSumergible.pdf";

const submersibleAccessoriesProductList = [
  {
    _id: "subcatacc001a",
    image: submersibleproduct1,
    title: "Check Pascon",
    code: "",
    category: "BOSHART",
    subcategory: "CHECK PASCON",
    downLoad: "none",
  },
  {
    _id: "subcatacc001b",
    image: submersibleproduct2,
    title: "Sello de Pozo",
    code: "",
    category: "BOSHART",
    subcategory: "SELLOS DE POZO",
    downLoad: "none",
  },
  {
    _id: "subcatacc002a",
    image: submersibleproduct3,
    title: "Check Vertical",
    code: "",
    category: "FLOMATIC",
    subcategory: "Check Vertical",
    downLoad: pdf3,
  },
  {
    _id: "subcatacc003a",
    image: submersibleproduct4,
    title: "Cajas de Control",
    code: "",
    category: "FRANKLIN ELECTRIC",
    subcategory: "CAJAS DE ARRANQUE",
    downLoad: pdf4,
  },
  {
    _id: "subcatacc003b",
    image: submersibleproduct5,
    title: "PUMPTEC Protección Para Bombas",
    code: "",
    category: "FRANKLIN ELECTRIC",
    subcategory: "PUMPTEC",
    downLoad: pdf5,
  },
  {
    _id: "subcatacc003c",
    image: submersibleproduct6,
    title: "SUBMONITOR CONNECT Protección Trifásica",
    code: "",
    category: "FRANKLIN ELECTRIC",
    subcategory: "SUBMONITOR",
    downLoad: pdf6,
  },
  {
    _id: "subcatacc004a",
    image: submersibleproduct7,
    title: "Cable de Bomba Sumergible",
    code: "",
    category: "KALAS",
    subcategory: "none",
    downLoad: pdf7,
  },
  {
    _id: "subcatacc005a",
    image: submersibleproduct8,
    title: "Cajas de Arranque",
    code: "",
    category: "PEARL",
    subcategory: "none",
    downLoad: "none",
  },
];

export default submersibleAccessoriesProductList;
