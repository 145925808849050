import poolproduct1 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/BPS.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/Hidro-SP.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/HML.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/HMT.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/bomb/hidrotermal/SPRIMAGEN.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/bomb/pentair/Optiflo.jpg";
import poolproduct7 from "../../../images/productsImages/poolImages/products/bomb/pentair/SUPERFLO.jpg";
import poolproduct8 from "../../../images/productsImages/poolImages/products/bomb/pentair/Whisperflo.jpg";
import pdf1 from "../../../pdf/pool/bomb/BPS.pdf";
import pdf2 from "../../../pdf/pool/bomb/HML.pdf";
import pdf5 from "../../../pdf/pool/bomb/SPR.pdf";
import pdf6 from "../../../pdf/pool/bomb/Optiflo.pdf";
import pdf7 from "../../../pdf/pool/bomb/SUPERFLO.pdf";
import pdf8 from "../../../pdf/pool/bomb/Whisperflo.pdf";

const poolBombProductList = [
  {
    _id: "poocatbomhidro001a",
    image: poolproduct1,
    title: "BPS",
    code: "BPS",
    category: "HIDROTERMAL",
    subcategory: "BPS",
    downLoad: pdf1,
  },
  {
    _id: "poocatbomhidro002a",
    image: poolproduct2,
    title: "HML",
    code: "HML",
    category: "HIDROTERMAL",
    subcategory: "HML",
    downLoad: pdf2,
  },
  {
    _id: "poocatbomhidro003a",
    image: poolproduct3,
    title: "HMT",
    code: "HMT",
    category: "HIDROTERMAL",
    subcategory: "HMT",
    downLoad: "none",
  },
  {
    _id: "poocatbomhidro004a",
    image: poolproduct4,
    title: "SP",
    code: "SP",
    category: "HIDROTERMAL",
    subcategory: "SP",
    downLoad: "none",
  },
  {
    _id: "poocatbomhidro005a",
    image: poolproduct5,
    title: "SPR",
    code: "SPR",
    category: "HIDROTERMAL",
    subcategory: "SPR",
    downLoad: pdf5,
  },
  {
    _id: "poocatbompent001a",
    image: poolproduct6,
    title: "Optiflo",
    code: "",
    category: "PENTAIR",
    subcategory: "OPTIFLO",
    downLoad: pdf6,
  },
  {
    _id: "poocatbompent002a",
    image: poolproduct7,
    title: "Superflo",
    code: "",
    category: "PENTAIR",
    subcategory: "SUPERFLO",
    downLoad: pdf7,
  },
  {
    _id: "poocatbompent003a",
    image: poolproduct8,
    title: "Wisperflo",
    code: "",
    category: "PENTAIR",
    subcategory: "WISPERFLO",
    downLoad: pdf8,
  },
];

export default poolBombProductList;
