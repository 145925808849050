import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/LGmsp.jpg";
import submersibleproduct2 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/PESPHOTO.jpg";
import submersibleproduct3 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIE6.jpg";
import submersibleproduct4 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIE10.jpg";
import submersibleproduct5 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/LG/SERIELUP.jpg";
import submersibleproduct6 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/PEARL/SLP.png";
import submersibleproduct7 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasFuenteAchique/PEARL/PEARL-TECHDATA-SSP.png";
import submersibleproduct8 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/C1.jpg";
import submersibleproduct9 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/HI-CAP.jpg";
import submersibleproduct10 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/FE/TRI-SEAL.jpg";
import submersibleproduct11 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/PEARL/MINISUB.jpg";
import submersibleproduct12 from "../../../images/productsImages/submersibleImages/products/Residencial/BombasSumergibles/PEARL/PWP-01.png";
import submersibleproduct13 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/FRANKLINELECTRIC/ALTOeMPUJE.jpg";
import submersibleproduct14 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/FRANKLINELECTRIC/SUPERINOX.jpg";
import submersibleproduct15 from "../../../images/productsImages/submersibleImages/products/Residencial/MotoresSumergibles/PEARL/4MOPPearl.jpg";

import pdf1 from "../../../pdf/submersible/residential/5msp.pdf";
import pdf2 from "../../../pdf/submersible/residential/BombasFuentesPES.pdf";
import pdf3 from "../../../pdf/submersible/residential/Series6.pdf";
import pdf4 from "../../../pdf/submersible/residential/serie10.pdf";
import pdf5 from "../../../pdf/submersible/residential/Serie-LUP.pdf";
import pdf6 from "../../../pdf/submersible/residential/SLP.pdf";
import pdf7 from "../../../pdf/submersible/residential/SSP.pdf";
import pdf8 from "../../../pdf/submersible/residential/serie-c1.pdf";
import pdf9 from "../../../pdf/submersible/residential/HI-CAP.pdf";
import pdf10 from "../../../pdf/submersible/residential/Tri-Seal.pdf";
import pdf11 from "../../../pdf/submersible/residential/mini-sub.pdf";
import pdf12 from "../../../pdf/submersible/residential/PWP.pdf";
import pdf13 from "../../../pdf/submersible/residential/submersible-motor-4in.pdf";
import pdf14 from "../../../pdf/submersible/residential/submersible-motor.pdf";
import pdf15 from "../../../pdf/submersible/residential/4mop.pdf";

const fe = "FRANKLIN ELECTRIC";

const submersibleResidentialProductList = [
  {
    _id: "subcatresbomlg001a",
    image: submersibleproduct1,
    title: "Bombas Utilitaria Serie 5-MSP",
    code: "5-MSP",
    category: "Bombas Fuente y Achique",
    subcategory: "LITTLE GIANT",
    downLoad: pdf1,
  },
  {
    _id: "subcatresbomlg001b",
    image: submersibleproduct2,
    title: "Bombas Sumergibles de Acople Indirecto",
    code: "PES",
    category: "Bombas Fuente y Achique",
    subcategory: "LITTLE GIANT",
    downLoad: pdf2,
  },
  {
    _id: "subcatresbomlg001c",
    image: submersibleproduct3,
    title: "SUMP PUMPS 6 Series",
    code: "",
    category: "Bombas Fuente y Achique",
    subcategory: "LITTLE GIANT",
    downLoad: pdf3,
  },
  {
    _id: "subcatresbomlg001d",
    image: submersibleproduct4,
    title: "EFFLUENT PUMPS Serie 10",
    code: "",
    category: "Bombas Fuente y Achique",
    subcategory: "LITTLE GIANT",
    downLoad: pdf4,
  },
  {
    _id: "subcatresbomlg001e",
    image: submersibleproduct5,
    title: "Bomba Sumergible Utilitaria Serie LUP",
    code: "",
    category: "Bombas Fuente y Achique",
    subcategory: "LITTLE GIANT",
    downLoad: pdf5,
  },
  {
    _id: "subcatresbomPEARL001a",
    image: submersibleproduct6,
    title: "SLP Electrobombas Sumergibles para Aguas Limpias",
    code: "SLP",
    category: "Bombas Fuente y Achique",
    subcategory: "PEARL",
    downLoad: pdf6,
  },
  {
    _id: "subcatresbomPEARL001b",
    image: submersibleproduct7,
    title: "SSP Electrobombas Sumergibles para Aguas Sucias",
    code: "SSP",
    category: "Bombas Fuente y Achique",
    subcategory: "PEARL",
    downLoad: pdf7,
  },
  {
    _id: "subcatresbomsumfe001a",
    image: submersibleproduct8,
    title: "Bombas de Cisterna Serie C1",
    code: "",
    category: "Bombas Sumergibles",
    subcategory: fe,
    downLoad: pdf8,
  },
  {
    _id: "subcatresbomsumfe001b",
    image: submersibleproduct9,
    title: "TRI-SEAL HIGH CAPACITY 4",
    code: "HI-CAP",
    category: "Bombas Sumergibles",
    subcategory: fe,
    downLoad: pdf9,
  },
  {
    _id: "subcatresbomsumfe001c",
    image: submersibleproduct10,
    title: "TRI-SEAL Sistema de Etapa Flotante",
    code: "TRI-SEAL",
    category: "Bombas Sumergibles",
    subcategory: fe,
    downLoad: pdf10,
  },
  {
    _id: "subcatresbomsumpearl001a",
    image: submersibleproduct11,
    title: "Bomba Sumergible Multietapas MINISUB",
    code: "MINISUB",
    category: "Bombas Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf11,
  },
  {
    _id: "subcatresbomsumpearl001b",
    image: submersibleproduct12,
    title: "Bomba Sumergible 4PWP",
    code: "4PWP",
    category: "Bombas Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf12,
  },
  {
    _id: "subcatresmotfra001a",
    image: submersibleproduct13,
    title: `Motor Sumergible de Alto Empuje 4"`,
    code: "",
    category: "Motores Sumergibles",
    subcategory: fe,
    downLoad: pdf13,
  },
  {
    _id: "subcatresmotfra001b",
    image: submersibleproduct14,
    title: "Motor Sumergible de Super Inox",
    code: "",
    category: "Motores Sumergibles",
    subcategory: fe,
    downLoad: pdf14,
  },
  {
    _id: "subcatresmotpearl001a",
    image: submersibleproduct15,
    title: "4MOP Motor Sumergible",
    code: "4MOP",
    category: "Motores Sumergibles",
    subcategory: "PEARL",
    downLoad: pdf15,
  },
];

export default submersibleResidentialProductList;
