import cleaning from "../../images/productsImages/poolImages/cleaning.jpg";
import embed from "../../images/productsImages/poolImages/hidrotermal.jpg";
import waterfalls from "../../images/productsImages/poolImages/waterfalls.jpg";
import bombs from "../../images/productsImages/poolImages/bombs.jpg";
import filter from "../../images/productsImages/poolImages/filter.jpg";
import lighting from "../../images/productsImages/poolImages/lighting.jpg";
import chemicals from "../../images/productsImages/poolImages/chemicals.jpg";
import heating from "../../images/productsImages/poolImages/heating.jpg";
import disinfection from "../../images/productsImages/poolImages/disinfection.jpg";
import cleaningIcon from "../../images/productsImages/poolImages/cleaningIcon.svg";
import embedIcon from "../../images/productsImages/poolImages/embedIcon.svg";
import waterfallsIcon from "../../images/productsImages/poolImages/waterfallsIcon.svg";
import bombsIcon from "../../images/productsImages/poolImages/bombsIcon.svg";
import filterIcon from "../../images/productsImages/poolImages/filterIcon.svg";
import lightingIcon from "../../images/productsImages/poolImages/lightingIcon.svg";
import chemicalsIcon from "../../images/productsImages/poolImages/chemicalsIcon.svg";
import heatingIcon from "../../images/productsImages/poolImages/heatingIcon.svg";
import disinfectionIcon from "../../images/productsImages/poolImages/disinfectionIcon.svg";

const cleaningList = [
  {
    _id: "poocatcle001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "Aspiradora", _id: "poocatclehidro001" },
      { name: "Barra Telescopica", _id: "poocatclehidro002" },
      { name: "Cepillos", _id: "poocatclehidro003" },
      { name: "Mangueras", _id: "poocatclehidro004" },
      { name: "Recolector de hojas", _id: "poocatclehidro005" },
      { name: "Robots", _id: "poocatclehidro006" },
    ],
  },
  {
    _id: "poocatcle002",
    title: "PENTAIR",
    subcategory: [
      { name: "Aspiradora", _id: "poocatclepent001" },
      { name: "Cepillos", _id: "poocatclepent002" },
      { name: "Recolector de hojas", _id: "poocatclepent003" },
      { name: "Robots", _id: "poocatclepent004" },
    ],
  },
];

const embedList = [
  {
    _id: "poocatemb001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "Boquillas", _id: "poocatembhidro001" },
      { name: "Rejillas", _id: "poocatembhidro002" },
      { name: "Skimmers", _id: "poocatembhidro003" },
      { name: "Sumideros", _id: "poocatembhidro004" },
    ],
  },
];

const decorationList = [
  {
    _id: "poocatdeco001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "Cascada", _id: "poocatdecohidro001" },
      { name: "Cascada Serie WF", _id: "poocatdecohidro002" },
      { name: "Escalera", _id: "poocatdecohidro003" },
    ],
  },
];

const bombPoolList = [
  {
    _id: "poocatbomb001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "BPS", _id: "poocatbomhidro001" },
      { name: "HML", _id: "poocatbomhidro002" },
      { name: "HMT", _id: "poocatbomhidro003" },
      { name: "SP", _id: "poocatbomhidro004" },
      { name: "SPR", _id: "poocatbomhidro005" },
    ],
  },
  {
    _id: "poocatbomb002",
    title: "PENTAIR",
    subcategory: [
      { name: "OPTIFLO", _id: "poocatbompent001" },
      { name: "SUPERFLO", _id: "poocatbompent002" },
      { name: "WISPERFLO", _id: "poocatbompent003" },
    ],
  },
];

const filterList = [
  {
    _id: "poocatfil001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "HT-BF", _id: "poocatfilhidro001" },
      { name: "HT-PG", _id: "poocatfilhidro002" },
      { name: "HT-V", _id: "poocatfilhidro003" },
    ],
  },
  {
    _id: "poocatbomb002",
    title: "PENTAIR",
    subcategory: [
      { name: "CLEAN AND CLEAR", _id: "poocatfilpent001" },
      { name: "SAND DOLLAR", _id: "poocatfilpent002" },
      { name: "TAGELUS", _id: "poocatfilpent003" },
      { name: "TRITON", _id: "poocatfilpent004" },
    ],
  },
];
const lightningList = [
  {
    _id: "poocatlig001",
    title: "HIDROTERMAL",
  },
  {
    _id: "poocatlig002",
    title: "PENTAIR",
  },
];

const chemicalList = [
  {
    _id: "poocatche001",
    title: "CHEMICALS",
    subcategory: [
      { name: "Alguicida", _id: "poocatche001" },
      { name: "Clasificador", _id: "poocatche002" },
      { name: "CLORO SHOCK", _id: "poocatche003" },
      { name: "Floculante", _id: "poocatche004" },
      { name: "MAS PH", _id: "poocatche005" },
      { name: "MAS PH LIQUIDO", _id: "poocatche006" },
      { name: "MENOS PH", _id: "poocatche007" },
      { name: "MENOS PH LIQUIDO", _id: "poocatche008" },
      { name: "TRICOLOR GRANULADO", _id: "poocatche009" },
      { name: "TRICOLO TABLETA", _id: "poocatche010" },
    ],
  },
];
const heatingList = [
  {
    _id: "poocatbomb001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "Calentador en Línea", _id: "poocatheahidro001" },
      { name: "H SERIES", _id: "poocatheahidro002" },
      { name: "Inverter Plus Top", _id: "poocatheahidro003" },
    ],
  },
  {
    _id: "poocatbomb002",
    title: "PENTAIR",
    subcategory: [
      { name: "MASTERTEMP", _id: "poocatheapent001" },
      { name: "ULTRA TEMP", _id: "poocatheapent002" },
    ],
  },
];

const disinfectionList = [
  {
    _id: "poocatbomb001",
    title: "HIDROTERMAL",
    subcategory: [
      { name: "Clorinador En Linea", _id: "poocatdishidro001" },
      { name: "Clorinador Flotante", _id: "poocatdishidro002" },
      { name: "Purificador UV-F", _id: "poocatdishidro003" },
      { name: "Purificador UV-ML", _id: "poocatdishidro004" },
      { name: "SC-MINI", _id: "poocatdishidro005" },
      { name: "SC-PRO", _id: "poocatdishidro006" },
    ],
  },
  {
    _id: "poocatbomb002",
    title: "PENTAIR",
    subcategory: [
      { name: "Clorinador R171070", _id: "poocatdispent001" },
      { name: "Clorinador Rainbow R320", _id: "poocatdispent002" },
      { name: "iChlor", _id: "poocatdispent003" },
    ],
  },
];

const poolList = [
  {
    _id: "poo001",
    image: cleaning,
    title: "Accesorios de Limpieza",
    link: "/products/pool/cleaning",
    list: cleaningList,
    path: "cleaning",
    icon: cleaningIcon,
  },
  {
    _id: "poo002",
    image: embed,
    title: "Accesorios para Empotrar",
    link: "/products/pool/embed",
    list: embedList,
    path: "embed",
    icon: embedIcon,
  },
  {
    _id: "poo003",
    image: waterfalls,
    title: "Accesorios y Decoración",
    link: "/products/pool/decoration",
    list: decorationList,
    path: "decoration",
    icon: waterfallsIcon,
  },
  {
    _id: "poo004",
    image: bombs,
    title: "Bombas de Piscina",
    link: "/products/pool/bombs",
    list: bombPoolList,
    path: "bombs",
    icon: bombsIcon,
  },
  {
    _id: "poo005",
    image: filter,
    title: "Filtros de Piscina",
    link: "/products/pool/filter",
    list: filterList,
    path: "filter",
    icon: filterIcon,
  },
  {
    _id: "poo006",
    image: lighting,
    title: "Iluminación",
    link: "/products/pool/lighting",
    list: lightningList,
    path: "lighting",
    icon: lightingIcon,
  },
  {
    _id: "poo007",
    image: chemicals,
    title: "Químicos de Piscina",
    link: "/products/pool/chemicals",
    list: chemicalList,
    path: "chemicals",
    icon: chemicalsIcon,
  },
  {
    _id: "poo008",
    image: heating,
    title: "Sistemas de calefacción",
    link: "/products/pool/heating",
    list: heatingList,
    path: "heating",
    icon: heatingIcon,
  },
  {
    _id: "poo009",
    image: disinfection,
    title: "Sistemas de desinfección",
    link: "/products/pool/disinfection",
    list: disinfectionList,
    path: "disinfection",
    icon: disinfectionIcon,
  },
];

export { poolList };
