import poolproduct1 from "../../../images/productsImages/poolImages/products/embed/MHWF-01CBoquilladeretorno.png";
import poolproduct2 from "../../../images/productsImages/poolImages/products/embed/PF-1020Boquilladerebalse.png";
import poolproduct3 from "../../../images/productsImages/poolImages/products/embed/PF-1022Boquilladeaspiración.png";
import poolproduct4 from "../../../images/productsImages/poolImages/products/embed/PF-1510Jethidromasaje.png";
import poolproduct5 from "../../../images/productsImages/poolImages/products/embed/PF-2831CBoquilladeaspiración.png";
import poolproduct6 from "../../../images/productsImages/poolImages/products/embed/PF-1032Rejilla.png";
import poolproduct7 from "../../../images/productsImages/poolImages/products/embed/PF-1036RejillaAntivortex.png";
import poolproduct8 from "../../../images/productsImages/poolImages/products/embed/PF-001Skimmer.png";
import poolproduct9 from "../../../images/productsImages/poolImages/products/embed/PF-009-1Skimmer.png";
import poolproduct10 from "../../../images/productsImages/poolImages/products/embed/PF-1030Sumidero.png";
import poolproduct11 from "../../../images/productsImages/poolImages/products/embed/PF-2010CSumidero.png";
import poolproduct12 from "../../../images/productsImages/poolImages/products/embed/PF-2011Sumidero.png";

const poolEmbedProductList = [
  {
    _id: "poocatembhidro001a",
    image: poolproduct1,
    title: "Boquilla de Retorno",
    code: "MHWF-01C",
    category: "HIDROTERMAL",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001b",
    image: poolproduct2,
    title: "Boquilla de Rebalse",
    code: "PF-1020",
    category: "HIDROTERMAL",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001c",
    image: poolproduct3,
    title: "Boquilla de Aspiración 1",
    code: "PF-1022",
    category: "HIDROTERMAL",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001d",
    image: poolproduct4,
    title: "Jet hidromasaje",
    code: "PF-1510 ",
    category: "HIDROTERMAL",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro001e",
    image: poolproduct5,
    title: "Boquilla de aspiración 2",
    code: "PF-2831C",
    category: "HIDROTERMAL",
    subcategory: "Boquillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro002a",
    image: poolproduct6,
    title: "Rejilla",
    code: "PF-1032",
    category: "HIDROTERMAL",
    subcategory: "Rejillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro002b",
    image: poolproduct7,
    title: "Rejilla",
    code: "PF-1036",
    category: "HIDROTERMAL",
    subcategory: "Rejillas",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro003a",
    image: poolproduct8,
    title: "Standar Skimmer",
    code: "PF-001",
    category: "HIDROTERMAL",
    subcategory: "Skimmers",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro003b",
    image: poolproduct9,
    title: "ABS Fiberglass Pool & Spa Skimmer",
    code: "PF-009-1",
    category: "HIDROTERMAL",
    subcategory: "Skimmers",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004a",
    image: poolproduct10,
    title: "Sumidero",
    code: "PF-1030",
    category: "HIDROTERMAL",
    subcategory: "Sumideros",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004b",
    image: poolproduct11,
    title: "Sumidero",
    code: "PF-2010C",
    category: "HIDROTERMAL",
    subcategory: "Sumideros",
    downLoad: "none",
  },
  {
    _id: "poocatembhidro004c",
    image: poolproduct12,
    title: "Sumidero ANTIVORTEX",
    code: "PF-2011",
    category: "HIDROTERMAL",
    subcategory: "Sumideros",
    downLoad: "none",
  },
];

export default poolEmbedProductList;
