import submersibleproduct1 from "../../../images/productsImages/submersibleImages/products/BombeoSolar/FAMILIASOLAR-01.png";
import pdf1 from "../../../pdf/submersible/solar/FAMILIASOLAR.pdf";

const submersibleResidentialProductList = [
  {
    _id: "subcatbombeosolar001a",
    image: submersibleproduct1,
    title: "Sistemas de Bombeo Solar",
    code: "",
    category: "Bombeo Solar",
    subcategory: "none",
    downLoad: pdf1,
  },
];

export default submersibleResidentialProductList;
