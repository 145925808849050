import adviceIcon from "../images/servicesImages/adviceIcon.svg";
import rentIcon from "../images/servicesImages/rentIcon.svg";
import engineeringIcon from "../images/servicesImages/engineeringIcon.svg";
import maintenanceIcon from "../images/servicesImages/maintenanceIcon.svg";
import workshopIcon from "../images/servicesImages/workshopIcon.svg";
import revisionIcon from "../images/servicesImages/revisionIcon.svg";
import bomb from "../images/servicesImages/bomb.JPG";
import workshop from "../images/servicesImages/bike-creation-in-workshop.jpg";
import engineer from "../images/servicesImages/engineer.jpg";
import advicesale from "../images/servicesImages/sale.jpg";
import workers from "../images/servicesImages/workers.jpg";
import well from "../images/servicesImages/well.JPG";

const servicesList = [
  {
    _id: "serv001",
    image: rentIcon,
    imageLeft: bomb,
    title: "Alquiler de equipos",
    text: "Ofrecemos servicios de alquiler de equipo.",
    paragraph:
      "Contamos con gran variedad de equipos de bombeo para alquiler, tanto sumergible como de superficie. Nos acoplamos a su necesidad con respecto a tiempos y presupuestos. Igualmente contamos con una planta generadora de 85 kw para sus servicios.",
    link: "/services/rent",
    path: "rent",
  },
  {
    _id: "serv002",
    image: adviceIcon,
    imageLeft: advicesale,
    title: "Asesoramiento y venta",
    text:
      "Brindamos atención personalizada, ofreciendo soluciones a las necesidades del cliente.",
    paragraph:
      "Contamos con personal calificado para atender cualquier requerimiento nuestros agentes tendrán todo el gusto y disponibilidad para asesorarlo.",
    link: "/services/advice",
    path: "advice",
  },
  {
    _id: "serv003",
    image: engineeringIcon,
    imageLeft: engineer,
    title: "Ingeniería y diseño",
    text:
      "Nuestros ingenieros están altamente calificados para atender sus necesidades.",
    paragraph:
      "Disponemos de profesionales con extenso conocimiento en diseño, construcción y mantenimiento de sistemas que implican manejo del agua, así como profesionales en automatización de sistemas de bombeo.",
    link: "/services/engineering",
    path: "engineering",
  },
  {
    _id: "serv004",
    image: maintenanceIcon,
    imageLeft: workers,
    title: "Mantenimientos",
    text: "Ofrecemos el servicio de mantenimiento",
    paragraph:
      "Tanto preventivo como correctivo de equipos y sistemas de bombeo en todo el país con ingenieros y técnicos especializados.",
    link: "/services/maintenance",
    path: "maintenance",
  },
  {
    _id: "serv005",
    image: revisionIcon,
    imageLeft: well,
    title: "Revisión de pozos",
    text: "Hacemos valoración del estado de pozos profundos.",
    paragraph:
      "También ofrecemos evaluación de aforo, limpieza y reparación de los mismos.Contamos con equipo especializado como: cámaras y sondas de control de nivel.",
    link: "/services/revision",
    path: "revision",
  },
  {
    _id: "serv006",
    image: workshopIcon,
    imageLeft: workshop,
    title: "Taller de servicio",
    text:
      "Contamos con espacios acondicionados para la realización de trabajos de diagnóstico, reparación y reconstrucción de equipos de bombeo.",
    paragraph:
      "Contamos con un taller de servicio debidamente equipado y con personal bastamente capacitado para valoración, diagnóstico y reparación de sus equipos de bombeo.",
    link: "/services/workshop",
    path: "workshop",
  },
];

export default servicesList;
