import React, { useState } from "react";
import { surfaceList } from "../../../Lists/products/surfaceList";
import Category from "../../../components/UI/Category";
import classes from "./surfaceProducts.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import surfaceResidentialProductList from "../../../Lists/products/surfaceProducts/surfaceResidentialList";
import surfaceIndustrialProductList from "../../../Lists/products/surfaceProducts/surfaceIndustrialList";

const SurfaceProducts = () => {
  const newArray = [];

  const params = useParams();

  const industrial = "industrial" === params.surfaceId;

  const listCategory = surfaceList.find(
    (category) => category.path === params.surfaceId
  );

  const findListHandle = (path) => {
    if (path === "industrial") {
      return surfaceIndustrialProductList;
    }
    if (path === "residential") {
      return surfaceResidentialProductList;
    }
  };

  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };

  return (
    <div className={classes.showProducts}>
      <section>
        <Subcategory
          onListChange={onListChange}
          onsubListChange={onsubListChange}
          categoryList={listCategory.list}
        ></Subcategory>
      </section>
      <section
        className={` ${
          industrial ? classes.fewCategories : classes.manyCategories
        }`}
      >
        <Category title={listCategory.title} list={displayedList}></Category>
      </section>
    </div>
  );
};

export default SurfaceProducts;
