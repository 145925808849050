import React, { useState, useEffect } from "react";
import classes from "./ContactForm.module.css";

const ContactForm = () => {
  const [enteredName, setEnteredName] = useState("");
  const [enteredLastname, setEnteredLastname] = useState("");
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredNumber, setEnteredNumber] = useState("");
  const [enteredMessage, setEnteredMessage] = useState("");

  const [isValid, setIsValid] = useState(false);

  const [nameIsValid, setNameIsValid] = useState();
  const [lastnameIsValid, setLastnameIsValid] = useState();
  const [emailIsValid, setEmailIsValid] = useState();
  const [numberIsValid, setNumberilIsValid] = useState();
  const [messageIsValid, setMessageIsValid] = useState();
  //name
  const nameChangeHandler = (event) => {
    setEnteredName(event.target.value);
  };
  const validateNameHandler = () => {
    setNameIsValid(enteredName.trim().length > 2);
  };
  //lastname
  const lastnameChangeHandler = (event) => {
    setEnteredLastname(event.target.value);
  };
  const validateLastnameHandler = () => {
    setLastnameIsValid(enteredName.trim().length > 1);
  };
  //email
  const emailChangeHandler = (event) => {
    setEnteredEmail(event.target.value);
  };
  const validateEmailHandler = () => {
    setEmailIsValid(enteredEmail.trim().length > 3);
  };
  //cellpone
  const numberChangeHandler = (event) => {
    setEnteredNumber(event.target.value);
  };
  const validateNumberHandler = () => {
    setNumberilIsValid(enteredNumber.trim().length > 8);
  };
  //message
  const messageChangeHandler = (event) => {
    setEnteredMessage(event.target.value);
  };
  const validateMessagetHandler = () => {
    setMessageIsValid(enteredMessage.trim().length > 7);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const contactData = {
      name: enteredName,
      lastname: enteredLastname,
      email: enteredEmail,
      number: enteredNumber,
      message: enteredMessage,
    };

    console.log(contactData);

    setEnteredName("");
    setEnteredLastname("");
    setEnteredEmail("");
    setEnteredNumber("");
    setEnteredMessage("");
  };

  useEffect(() => {
    const identifier = setTimeout(() => {
      setIsValid(
        enteredName.trim().length > 2 &&
          enteredLastname.trim().length > 3 &&
          enteredEmail.trim().length > 3 &&
          enteredNumber.trim().length > 3 &&
          enteredMessage.trim().length > 7
      );
    }, 500);
    return () => {
      clearTimeout(identifier);
    };
  }, [
    enteredName,
    enteredLastname,
    enteredEmail,
    enteredNumber,
    enteredMessage,
  ]);

  return (
    <form onSubmit={submitHandler}>
      <div className={classes.controls}>
        <div>
          <div className={`${nameIsValid === false ? classes.invalid : ""}`}>
            <label>Nombre</label>
            <input
              type="text"
              value={enteredName}
              onChange={nameChangeHandler}
              onBlur={validateNameHandler}
            ></input>
          </div>
          <div
            className={`${lastnameIsValid === false ? classes.invalid : ""}`}
          >
            <label>Apellidos</label>
            <input
              type="text"
              value={enteredLastname}
              onChange={lastnameChangeHandler}
              onBlur={validateLastnameHandler}
            ></input>
          </div>
        </div>
        <div className={`${emailIsValid === false ? classes.invalid : ""}`}>
          <label>Correo electrónico</label>
          <input
            type="text"
            value={enteredEmail}
            onChange={emailChangeHandler}
            onBlur={validateEmailHandler}
          ></input>
        </div>
        <div className={`${numberIsValid === false ? classes.invalid : ""}`}>
          <label>Teléfono</label>
          <input
            type="number"
            value={enteredNumber}
            onChange={numberChangeHandler}
            onBlur={validateNumberHandler}
          ></input>
        </div>
        <label htmlFor="category">
          ¿A cuál categoría se refiere su consulta?
        </label>
        <select
          className={classes.select}
          name="category"
          id="category"
          form="categoryform"
        >
          <option value="industrial">Industrial</option>
          <option value="mantenimiento">Mantenimiento</option>
          <option value="piscina">Piscina</option>
          <option value="Potencia">Potencia, control, monitoreo</option>
          <option value="Residencial">Residencial</option>
          <option value="otros">Otros</option>
        </select>
        <div className={`${messageIsValid === false ? classes.invalid : ""}`}>
          <label>Mensaje</label>
          <input
            className={classes.message}
            type="text"
            value={enteredMessage}
            onChange={messageChangeHandler}
            onBlur={validateMessagetHandler}
          ></input>
        </div>
        <div className={classes.action}>
          <button
            className={classes.button}
            type="submit"
            colortype="lighter"
            width="big"
            disabled={!isValid}
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  );
};
export default ContactForm;
