import React, { useState } from "react";
import { submersibleList } from "../../../Lists/products/submersibleList";
import Category from "../../../components/UI/Category";
import classes from "./submersibleProducts.module.css";
import Subcategory from "../../../components/Subcategorymenu";
import { useParams } from "react-router-dom";
import submersibleAccessoriesProductList from "../../../Lists/products/submersibleProducts/submersibleAccessoriesList";
import submersibleIndustrialProductList from "../../../Lists/products/submersibleProducts/submersibleIndustrialList";
import submersibleResidentialProductList from "../../../Lists/products/submersibleProducts/submersibleResidentialList";
import submersibleSolarBombProductList from "../../../Lists/products/submersibleProducts/submersibleSolarBombList";

const SubmersibleProducts = () => {
  const newArray = [];

  const params = useParams();

  const solarBomb = "solarbombs" === params.submersibleId;

  const showSubcategory = () => {
    if ("solarbombs" === params.submersibleId) {
      return;
    } else
      return (
        <section>
          <Subcategory
            onListChange={onListChange}
            onsubListChange={onsubListChange}
            categoryList={listCategory.list}
          ></Subcategory>
        </section>
      );
  };

  const listCategory = submersibleList.find(
    (category) => category.path === params.submersibleId
  );

  const findListHandle = (path) => {
    if (path === "accessories") {
      return submersibleAccessoriesProductList;
    }
    if (path === "solarbombs") {
      return submersibleSolarBombProductList;
    }
    if (path === "industrial") {
      return submersibleIndustrialProductList;
    }
    if (path === "residential") {
      return submersibleResidentialProductList;
    }
  };

  const originalList = findListHandle(listCategory.path);

  const [displayedList, setdisplayedList] = useState(
    findListHandle(listCategory.path)
  );

  const onListChange = (category) => {
    newArray.length = 0;
    originalList.map((element) => sort(element, category));
    setdisplayedList(newArray);
  };

  const sort = (element, category) => {
    if (element.category === category) {
      newArray.push(element);
    }
  };

  const onsubListChange = (category, secondCategory) => {
    newArray.length = 0;
    originalList.map((element) =>
      secondsort(element, category, secondCategory)
    );

    setdisplayedList(newArray);
  };
  const secondsort = (element, category, secondCategory) => {
    if (
      element.category === category &&
      element.subcategory === secondCategory
    ) {
      newArray.push(element);
    }
  };

  return (
    <div className={classes.showProducts}>
      {showSubcategory()}
      <section
        className={` ${solarBomb ? classes.onlyProducts : classes.products}`}
      >
        <Category title={listCategory.title} list={displayedList}></Category>
      </section>
    </div>
  );
};

export default SubmersibleProducts;
