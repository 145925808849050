import drive from "../../images/productsImages/pressureImages/drive.png";
import connect from "../../images/productsImages/pressureImages/connect.jpg";
import quickpak from "../../images/productsImages/pressureImages/quickpak.jpg";
import solarpak from "../../images/productsImages/pressureImages/solarpak.jpg";
import utility from "../../images/productsImages/pressureImages/utility.png";
import pdf1 from "../../pdf/pressure/DRIVETECHPRO.pdf";
import pdf2 from "../../pdf/pressure/SUBDRIVECONNECT.pdf";
import pdf3 from "../../pdf/pressure/SUBDRIVEQUICKPAK.pdf";
import pdf4 from "../../pdf/pressure/SUBDRIVESOLARPAK.pdf";
import pdf5 from "../../pdf/pressure/SUBDRIVEUTILITY.pdf";

const pressureList = [
  {
    _id: "pre001",
    image: drive,
    title: "Drive Tech Pro",
    code: "",
    downLoad: pdf1,
  },
  {
    _id: "pre002",
    image: connect,
    title: "SubDrive Connect",
    code: "",
    downLoad: pdf2,
  },
  {
    _id: "pre003",
    image: quickpak,
    title: "SubDrive Quickpak",
    code: "",
    downLoad: pdf3,
  },
  {
    _id: "pre004",
    image: solarpak,
    title: "SubDrive Solarpak",
    code: "",
    downLoad: pdf4,
  },
  {
    _id: "pre005",
    image: utility,
    title: "SubDrive Utility",
    code: "",
    downLoad: pdf5,
  },
];

export default pressureList;
