import accessories from "../../images/productsImages/submersibleImages/submersible.jpg";
import solarbomb from "../../images/productsImages/submersibleImages/panel.jpg";
import industrial from "../../images/productsImages/submersibleImages/industrial.jpg";
import residential from "../../images/productsImages/submersibleImages/residencial.jpg";
import accessoriesIcon from "../../images/productsImages/submersibleImages/accessoriesIcon.svg";
import solarbombIcon from "../../images/productsImages/submersibleImages/solarbombIcon.svg";
import industrialIcon from "../../images/productsImages/submersibleImages/industrialIcon.svg";
import residentialIcon from "../../images/productsImages/submersibleImages/residentialIcon.svg";

const fe = "FRANKLIN ELECTRIC";

const accessoriesList = [
  {
    _id: "subcatacc001",
    title: "BOSHART",
    subcategory: [
      { name: "CHECK PASCON", _id: "subcataccbos001" },
      { name: "SELLOS DE POZO", _id: "subcataccbos002" },
    ],
  },
  {
    _id: "subcatacc002",
    title: "FLOMATIC",
  },
  {
    _id: "subcatacc003",
    title: fe,
    subcategory: [
      { name: "CAJAS DE ARRANQUE", _id: "subcataccfra001" },
      { name: "PUMPTEC", _id: "subcataccfra002" },
      { name: "SUBMONITOR", _id: "subcataccfra003" },
    ],
  },
  {
    _id: "subcatacc004",
    title: "KALAS",
  },
  {
    _id: "subcatacc005",
    title: "PEARL",
  },
];

const solarBombList = [
  {
    _id: "subcatsol001",
    title: fe,
  },
];

const sumIndustrialList = [
  {
    _id: "subcatind001",
    title: "Aguas Negras",
    subcategory: [
      { name: "BGP", _id: "subcatindagubgp001" },
      { name: fe, _id: "subcatindagufe001" },
      { name: "LITTLE GIANT", _id: "subcatindagulg001" },
    ],
  },
  {
    _id: "subcatind002",
    title: "Alta Eficiencia",
  },
  {
    _id: "subcatind003",
    title: "Bombas Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatindbomfe001" },
      { name: "PEARL", _id: "subcatindbompearl001" },
    ],
  },
  {
    _id: "subcatind004",
    title: "Motores Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatindmotfe001" },
      { name: "PEARL", _id: "subcatindmotpearl001" },
    ],
  },
];

const sumResidencialList = [
  {
    _id: "subcatres001",
    title: "Bombas Fuente y Achique",
    subcategory: [
      { name: "LITTLE GIANT", _id: "subcatresbomlg001" },
      { name: "PEARL", _id: "subcatresbompearl001" },
    ],
  },
  {
    _id: "subcatres002",
    title: "Bombas Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatresbomsfe001" },
      { name: "PEARL", _id: "subcatresbomspearl001" },
    ],
  },
  {
    _id: "subcatind003",
    title: "Motores Sumergibles",
    subcategory: [
      { name: fe, _id: "subcatresmotfra001" },
      { name: "PEARL", _id: "subcatresmotpearl001" },
    ],
  },
];

const submersibleList = [
  {
    _id: "sum001",
    image: accessories,
    title: "Accesorios Sumergibles",
    link: "/products/submersible/accessories",
    list: accessoriesList,
    path: "accessories",
    icon: accessoriesIcon,
  },
  {
    _id: "sum002",
    image: solarbomb,
    title: "Bombeo Solar",
    link: "/products/submersible/solarbombs",
    list: solarBombList,
    path: "solarbombs",
    icon: solarbombIcon,
  },
  {
    _id: "sum003",
    image: industrial,
    title: "Industrial",
    link: "/products/submersible/industrial",
    list: sumIndustrialList,
    path: "industrial",
    icon: industrialIcon,
  },
  {
    _id: "sum004",
    image: residential,
    title: "Residencial",
    link: "/products/submersible/residential",
    list: sumResidencialList,
    path: "residential",
    icon: residentialIcon,
  },
];

export { submersibleList };
