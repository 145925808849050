import React from "react";
import ProductMenu from "../../components/UI/Productmenu";
import { submersibleList } from "../../Lists/products/submersibleList";
import classes from "./General.module.css";

const Submersible = () => {
  return (
    <div className={classes.productMenuPage}>
      <div className={classes.productMainInfo}>
        <h1>Equipos de Bombeo Sumergibles</h1>
        <p>
          Los equipos de bombeo sumergibles son nuestra especialidad,
          representamos marcas de reconocimiento mundial como Franklin Electric,
          Grundfos y Pearl, mantenemos en nuestras bodegas equipos de bombeo
          sumergibles desde 0.5 HP y hasta 200 HP para dar soluciones muy
          rápidas al suministro del agua que siempre es crítico. Hacemos
          instalaciones completas de equipos en pozos profundos, incluyendo los
          tableros de control.
        </p>
      </div>
      <ProductMenu list={submersibleList}></ProductMenu>
    </div>
  );
};

export default Submersible;
